export const headerCopy = {
    headline: `SMARTER, MORE EFFECTIVE MARKETING`,
    subHeadline: `Better relevancy, higher engagement, and best-of-class monetization with the most effective data management and analytics platform ever created.`,
    legal: `OpenMail, LLC holds investments in System1 and Protected.net.`
};

export const cardCopy = [
    {
        headline: `STORE YOUR DATA IN ONE PLACE`,
        body: `We’ll help you make sense of your data and keep you organized.`
    },
    {
        headline: `TAKE ACTION ON REAL INSIGHTS`,
        body: `Our mission is to provide the tools and insights to make you a rockstar marketer.`
    },
    {
        headline: `IT’S FREE. SERIOUSLY.`,
        body: `Our platform is designed to be accessible to all marketers, regardless of budget.`
    }
];
