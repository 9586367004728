import React, {FC} from 'react';
import {css} from '@emotion/core';
import {Box, Text} from 'theme-ui';

type TextCardType = {
    headline: string;
    body: string;
};

const TextCard: FC<TextCardType> = ({headline, body}) => {
    const TextCardCss = css`
        margin: 0 auto;
        padding: 0 5%;
        text-align: center;
    `;

    const bodyCss = css`
        font-family: Arial;
        font-size: 16px;
        margin: 6% 9%;
    `;

    const ParagraphCss = css`
        padding: 5% 0 0;
        @media (min-width: 832px) {
            padding: 0 24%;
        }
    `;

    return (
        <Box css={TextCardCss}>
            <Text variant="paragraph" css={ParagraphCss}>
                {headline}
            </Text>
            <Text css={bodyCss}>{body}</Text>
        </Box>
    );
};

export default TextCard;
