/** @jsx jsx */
/* eslint-disable react/no-unescaped-entities */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, {FC} from 'react';
import {Flex, Heading, Text, Grid, Image, jsx, ThemeProvider, useThemeUI} from 'theme-ui';
import {Container} from '@material-ui/core';
import {css} from '@emotion/core';
import {cardCopy, headerCopy} from '../data/text';
import TextCard from '../components/TextCard';
import OpenmailLogo from '../assets/images/logo.svg';

const Index: FC = () => {
    const {theme} = useThemeUI();
    const {headline, subHeadline, legal} = headerCopy;

    const LogoCss = css`
        display: flex;
        margin: 5% auto;
    `;

    const HeadlineCss = css`
        padding: 0;
        @media (min-width: 832px) {
            padding: 0 22%;
        }
    `;

    const ParagraphCss = css`
        padding: 0;
        @media (min-width: 832px) {
            padding: 0 24%;
        }
    `;

    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth="lg">
                <Flex>
                    <Image src={OpenmailLogo} width="300px" height="auto" css={LogoCss} />
                </Flex>
                <Flex>
                    <Heading as="h1" css={HeadlineCss}>
                        {headline}
                    </Heading>
                </Flex>
                <Flex>
                    <Text variant="paragraph" css={ParagraphCss}>
                        {subHeadline}
                    </Text>
                </Flex>
                <Flex>
                    <Text variant="description">{legal}</Text>
                </Flex>
                <Flex>
                    <Grid columns={[1, 1, 3]} style={{width: '100%'}}>
                        {cardCopy.map((card) => {
                            return <TextCard headline={card.headline} body={card.body} />;
                        })}
                    </Grid>
                </Flex>
            </Container>
        </ThemeProvider>
    );
};

export default Index;
